import PropTypes from 'prop-types';
import React from 'react';

import { Button, ButtonBar } from '../../../../../common/components';
import { LsiContext } from '../../../../../common/contexts';
import {
  BUpdateBalanceFunctionalityContext,
  PCGetProductionCorridorListFunctionalityContext,
  PCUpdateProductionCorridorBulkFunctionalityContext
} from '../../../../privilege/context';

const pcGetProductionCorridorListFunctionalityContext = new PCGetProductionCorridorListFunctionalityContext();
const pcUpdateProductionCorridorBulkFunctionalityContext = new PCUpdateProductionCorridorBulkFunctionalityContext();
const bUpdateBalanceFunctionalityContext = new BUpdateBalanceFunctionalityContext();

class ProductionCorridorButtonBar extends React.PureComponent {

  static contextType = LsiContext;

  static propTypes = {
    editable: PropTypes.bool,
    onAddModelGroup: PropTypes.func.isRequired,
    onCancelEdit: PropTypes.func.isRequired,
    onEnableEdit: PropTypes.func.isRequired,
    onReload: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onRecalculateBalance: PropTypes.func.isRequired
  };

  static defaultProps = {
    editable: false
  };

  _renderReadButtons = () => {
    const lsi = this.context;
    const { onEnableEdit, onReload, onRecalculateBalance } = this.props;

    return (
      <React.Fragment>
        <Button
          content={lsi.getLSIItem('PVT.BUTTON.EDIT')}
          key="ovex-ProductionCorridorButtonBar-btn-eidt"
          onClick={onEnableEdit}
          privilegeFunctionalityContext={pcUpdateProductionCorridorBulkFunctionalityContext}
          type={Button.type.PRIMARY}
        />
        <Button
          content={lsi.getLSIItem('PVT.BUTTON.RELOAD')}
          key="ovex-ProductionCorridorButtonBar-btn-reload"
          onClick={onReload}
          privilegeFunctionalityContext={pcGetProductionCorridorListFunctionalityContext}
          type={Button.type.SECONDARY}
        />
        <Button
          content={lsi.getLSIItem('PVT.BUTTON.BALANCE')}
          key="ovex-ProductionCorridorButtonBar-btn-balance"
          onClick={onRecalculateBalance}
          privilegeFunctionalityContext={bUpdateBalanceFunctionalityContext}
          type={Button.type.SECONDARY}
        />
      </React.Fragment>
    );
  };

  _renderEditButtons = () => {
    const lsi = this.context;
    const { onAddModelGroup, onCancelEdit, onSave, onRecalculateBalance } = this.props;

    return (
      <React.Fragment>
        <Button
          content={lsi.getLSIItem('PVT.BUTTON.CANCEL')}
          key="ovex-ProductionCorridorButtonBar-btn-cancel"
          onClick={onCancelEdit}
          type={Button.type.SECONDARY}
        />
        <Button
          content={lsi.getLSIItem('PVT.BUTTON.ADD_MODEL_GROUP')}
          key="ovex-ProductionCorridorButtonBar-btn-addModelGroup"
          onClick={onAddModelGroup}
          type={Button.type.PRIMARY}
        />
        <Button
          content={lsi.getLSIItem('PVT.BUTTON.SAVE')}
          key="ovex-ProductionCorridorButtonBar-btn-save"
          onClick={onSave}
          privilegeFunctionalityContext={pcUpdateProductionCorridorBulkFunctionalityContext}
          type={Button.type.PRIMARY}
        />
        <Button
          content={lsi.getLSIItem('PVT.BUTTON.RELOAD')}
          disabled
          key="ovex-ProductionCorridorButtonBar-btn-reload"
          type={Button.type.SECONDARY}
        />
        <Button
          content={lsi.getLSIItem('PVT.BUTTON.BALANCE')}
          disabled
          key="ovex-ProductionCorridorButtonBar-btn-balance"
          onClick={onRecalculateBalance}
          privilegeFunctionalityContext={bUpdateBalanceFunctionalityContext}
          type={Button.type.SECONDARY}
        />
      </React.Fragment>
    );
  };

  render() {
    const { editable } = this.props;

    let buttons;
    if (editable) {
      buttons = this._renderEditButtons();
    } else {
      buttons = this._renderReadButtons();
    }

    return (
      <ButtonBar className="ovex-ProductionCorridorButtonBar">
        {buttons}
      </ButtonBar>
    );
  }
}

export default ProductionCorridorButtonBar;
