import {
  COLUMN_GROUP_IDS,
  COLUMN_IDS,
  tableBaseColumnDefs,
  tableBaseColumnGroupDefs
} from '../utils/tableBaseColumnDefs';

import { handleNumberValueFormatter } from '../utils/helpers';
import { simpleNullishValueFormatter } from '../../../../common/utils/formatters';
import { calculateRemainsNumber, handleDonePercentNumberValueGetter } from '../../../utils/statisticsHelpers';

const COLUMN_TYPE_ENUM = {
  DEFAULT_CD: 'defaultDefinitionColumn',
  DATA_CD: 'dataDefinitionColumn'
};

export const columnTypes = {
  [COLUMN_TYPE_ENUM.DEFAULT_CD]: {
    sortable: true,
    filter: true,
    filterParams: { newRowsAction: 'keep' },
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab']
  },
  [COLUMN_TYPE_ENUM.DATA_CD]: {
    cellClass: ['ovex-DealerBEVAnnualTargetSalesTable-cell-numeric'],
    headerClass: ['ovex-ag-grid--th-center'],
    valueFormatter: handleNumberValueFormatter
  }
};
export const getColumnDefinitions = (lsi) => {
  return [
    {
      width: tableBaseColumnDefs[COLUMN_IDS.RCP_BEV].width,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.RCP_BEV'),
      valueGetter: () => lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.TOTAL'),
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      cellStyle: { paddingLeft: '50px' },
      pinned: 'left'
    },
    {
      ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_WORKING_GROUP],
      children: [
        {
          ...tableBaseColumnDefs[COLUMN_IDS.AT_ATS_DEALER_NUMBER],
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.TENTATIVE'),
          valueGetter: 'data && data.atsDealerNumber',
          valueFormatter: (valueFormatterParams) => simpleNullishValueFormatter(valueFormatterParams.value, '-'),
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD],
        },
        {
          ...tableBaseColumnDefs[COLUMN_IDS.AT_ATS_RECOMMENDED_NUMBER],
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.RECOMMENDED'),
          valueGetter: 'data && data.atsRecommendedNumber',
          valueFormatter: (valueFormatterParams) => simpleNullishValueFormatter(valueFormatterParams.value, '-'),
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
        },
        {
          ...tableBaseColumnDefs[COLUMN_IDS.AT_ATS_APPROVAL_NUMBER],
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.BINDING'),
          valueGetter: 'data && data.atsApprovalNumber',
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD],
        }
      ]
    },
    {
      ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_APPROVED_GROUP],
      children: [
        {
          ...tableBaseColumnDefs[COLUMN_IDS.AT_ATS_APPROVED_NUMBER],
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.APPROVED'),
          valueGetter: 'data && data.atsApprovedNumber',
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
        }
      ]
    },
    {
      ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_DONE_GROUP],
      headerClass: [],
      children: [
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_INVOICED_COUNT],
          headerClass: [],
          menuTabs: []
        },
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_PRODUCTION_COUNT],
          headerClass: [],
          menuTabs: []
        },
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_PLANNED_COUNT],
          headerClass: [],
          menuTabs: []
        },
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_DONE_NUMBER],
          valueGetter: 'data && data.atsStatisticsSoldCount',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DONE'),
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
        }
      ]
    },
    {
      ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_DONE_PERCENT_GROUP],
      children: [
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_DONE_PERCENTAGE],
          valueGetter: (valueGetterParams) => handleDonePercentNumberValueGetter(valueGetterParams, valueGetterParams.data.atsApprovedNumber, valueGetterParams.data.atsStatisticsSoldCount),
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DONE_PERCENT'),
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD],
          valueFormatter: null
        }
      ]
    },
    {
      ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_REMAINING_GROUP],
      children: [
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_REMAINING_NUMBER],
          valueGetter: (valueGetterParams) => calculateRemainsNumber(valueGetterParams.data.atsApprovedNumber, valueGetterParams.data.atsStatisticsSoldCount),
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.REMAINS'),
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
        }
      ]
    },
    {
      ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_POTENTIAL_GROUP],
      headerName: [],
      children: [
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_POTENTIAL_PRODUCTION_COUNT],
          headerClass: [],
          menuTabs: []
        },
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_POTENTIAL_PLANNED_COUNT],
          headerClass: [],
          menuTabs: []
        },
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_POTENTIAL_COUNT],
          headerClass: [],
          menuTabs: []
        }
      ]
    }
  ];
};