import React, { Fragment } from 'react';
import { Bricks } from 'uu5g04';
import { ProductionCorridorModelGroupChangeDTO } from '@ovex/pvt-web-api';
import PropTypes from 'prop-types';

import 'uu5g04/forms';
import { Button, ButtonBar } from '../../../../../common/components';
import { LsiContext } from '../../../../../common/contexts';
import './DealerConfigProductionCorridorButtonBar.scss';
import { Checkbox } from '../../../../../common/components/forms';

const propTypes = {
  changedModelGroups: PropTypes.arrayOf(PropTypes.object),
  editable: PropTypes.bool,
  onReloadProductionCorridor: PropTypes.func,
  onUpdateProductionCorridor: PropTypes.func,
  pageDescription: PropTypes.shape({
    generalSettingDescription: PropTypes.string,
    legend: PropTypes.string,
    legendDescriptionOff: PropTypes.string,
    legendDescriptionOn: PropTypes.string,
    specificSettingDescription: PropTypes.string,
    tableSpecification: PropTypes.string
  }),
  setChangedModelGroups: PropTypes.func,
  setEditable: PropTypes.func
};

const defaultProps = {
  changedModelGroups: [],
  editable: false,
  onReloadProductionCorridor: undefined,
  onUpdateProductionCorridor: undefined,
  pageDescription: PropTypes.shape({
    generalSettingDescription: null,
    legend: null,
    legendDescriptionOff: null,
    legendDescriptionOn: null,
    specificSettingDescription: null,
    tableSpecification: null
  }),
  setChangedModelGroups: undefined,
  setEditable: undefined
};

const DealerConfigProductionCorridorButtonBar = (props) => {
  const lsi = React.useContext(LsiContext);
  const { editable, setEditable, pageDescription, changedModelGroups } = props;
  const { onReloadProductionCorridor, onUpdateProductionCorridor, setChangedModelGroups } = props;

  const handleSaveProductionCorridorChanges = async () => {
    const modelGroupChanges = [];

    changedModelGroups.forEach((modelGroup) => {
      modelGroupChanges.push(ProductionCorridorModelGroupChangeDTO.constructFromObject(modelGroup));
    });

    if (modelGroupChanges.length > 0) {
      try {
        const response = await onUpdateProductionCorridor({
          modelGroupChanges: modelGroupChanges
        });
        // response && onReloadProductionCorridor();
      } catch (e) {
      }
    }
  };

  let buttons;
  if (editable) {
    buttons = <Fragment>
      <Button
        content={lsi.getLSIItem('PVT.BUTTON.CANCEL')}
        onClick={() => {
          setEditable(false);
          setChangedModelGroups([]);
        }}
        type={Button.type.SECONDARY}
      />
      <Button
        content={lsi.getLSIItem('PVT.BUTTON.SAVE')}
        onClick={() => {
          handleSaveProductionCorridorChanges();
        }}
        type={Button.type.PRIMARY}
      />
    </Fragment>;
  } else {
    buttons = <Fragment>
      <Button
        content={lsi.getLSIItem('PVT.BUTTON.EDIT')}
        onClick={() => {
          setEditable(true);
        }}
        type={Button.type.PRIMARY}
      />
      <Button
        content={lsi.getLSIItem('COMMON.BUTTON.RELOAD')}
        onClick={() => {
          onReloadProductionCorridor();
        }}
        type={Button.type.SECONDARY}
      />
    </Fragment>;
  }

  const LegendItem = ({ checked, text }) => (
    <div className="legend-item">
      <Checkbox disableChange type={1} value={checked}/>
      <Bricks.Text>{text}</Bricks.Text>
    </div>
  );

  return (
    <div className="ovex-dealer-config-button-bar">
      <div>
        <Bricks.Text className="general-setting">{pageDescription.generalSettingDescription}</Bricks.Text>
        <ButtonBar>{buttons}</ButtonBar>
      </div>
      <div className="legend">
        <div className="vertical-line"/>
        <div>
          <Bricks.Text>{pageDescription.legend}</Bricks.Text>
          <LegendItem checked={true} text={pageDescription.legendDescriptionOn}/>
          <LegendItem checked={false} text={pageDescription.legendDescriptionOff}/>
        </div>
      </div>
    </div>
  );
};

DealerConfigProductionCorridorButtonBar.propTypes = propTypes;
DealerConfigProductionCorridorButtonBar.defaultProps = defaultProps;

export default DealerConfigProductionCorridorButtonBar;