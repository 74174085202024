import React, { Fragment } from 'react';
import 'uu5g04/bricks';
import './DealerConfig.scss';
import PropTypes from 'prop-types';
import { ProductionCorridorDTO } from '@ovex/pvt-web-api';

import DealerConfigProductionCorridorTable
  from './components/DealerConfigProductionCorridorTable/DealerConfigProductionCorridorTable';

const propTypes = {
  attributeName: PropTypes.string.isRequired,
  onReloadProductionCorridor: PropTypes.func,
  onUpdateProductionCorridor: PropTypes.func,
  pageDescription: PropTypes.shape({
    generalSettingDescription: PropTypes.string,
    legend: PropTypes.string,
    legendDescriptionOff: PropTypes.string,
    legendDescriptionOn: PropTypes.string,
    specificSettingDescription: PropTypes.string,
    tableSpecification: PropTypes.string
  }),
  productionCorridorData: PropTypes.arrayOf(PropTypes.instanceOf(ProductionCorridorDTO))
};

const defaultProps = {
  onReloadProductionCorridor: undefined,
  onUpdateProductionCorridor: undefined,
  pageDescription: PropTypes.shape({
    generalSettingDescription: null,
    legend: null,
    legendDescriptionOff: null,
    legendDescriptionOn: null,
    specificSettingDescription: null,
    tableSpecification: null
  }),
  productionCorridorData: PropTypes.arrayOf(PropTypes.instanceOf(ProductionCorridorDTO))
};

const DealerConfig = (props) => {
  const [editableProductionCorridor, setEditableProductionCorridor] = React.useState(false);
  const [editableSpecification, setEditableSpecification] = React.useState(false);

  const { pageDescription, attributeName } = props;
  const { onReloadProductionCorridor, onUpdateProductionCorridor } = props;

  const preparedData = props.productionCorridorData?.filter((item) => item.modelMask === null);

  return (
    <Fragment>
      <DealerConfigProductionCorridorTable
        attributeName={attributeName}
        editable={editableProductionCorridor}
        onReloadProductionCorridor={onReloadProductionCorridor}
        onSetEditableProductionCorridor={setEditableProductionCorridor}
        onUpdateProductionCorridor={onUpdateProductionCorridor}
        pageDescription={pageDescription}
        productionCorridorData={preparedData}
      />
    </Fragment>
  );
};

DealerConfig.defaultProps = defaultProps;
DealerConfig.propTypes = propTypes;

export default DealerConfig;
