import React from 'react';
import PropTypes from 'prop-types';
import { ContractPreviewDTO } from '@ovex/annual-target-web-api';

import ContractAnnualTargetPreviewTable from '../ContractAnnualTargetPreviewTable/ContractAnnualTargetPreviewTable';
import ContractAnnualTargetSalesPreviewTable
  from '../ContractAnnualTargetSalesPreviewTable/ContractAnnualTargetSalesPreviewTable';
import ContractBEVAnnualTargetSalesPreviewTable
  from '../ContractBEVAnnualTargetPreviewTable/ContractBEVAnnualTargetSalesPreviewTable';

import './ContractPreview.scss';
import './ContractPreview.print.scss';

const propTypes = {
  className: PropTypes.string,
  contractPreview: PropTypes.instanceOf(ContractPreviewDTO)
};

const defaultProps = {
  className: null,
  contractPreview: null
};

const ContractPreview = React.memo((props) => {
  const classNames = ['ovex-at-ContractPreviewPage'];
  props.className && classNames.push(props.className);

  const cp = props.contractPreview;

  if (!cp) {
    return null;
  }

  const existsBEV = cp.annualTargetSalesBevData.tableContent.some(item => item.annualTargetNumber !== null);

  const companyRenderer = (company) => {
    return (
      <div
        className='ovex-at-ContractPreview-company'
      >
        <div
          className='ovex-at-ContractPreview-company-header'
        >
          {company.heading}
        </div>
        <div
          className='ovex-at-ContractPreview-company-address'
        >
          {`${company.address.name}\n${company.address.street}\n${company.address.postCodeAndCity}`}
        </div>
        <div
          className='ovex-at-ContractPreview-company-number'
        >
          {company.companyNumber}
        </div>
      </div>
    );
  };

  const textBlockRenderer = (header, text) => {
    return (
      <div
        className='ovex-at-ContractPreview-textBlock'
      >
        <div
          className='ovex-at-ContractPreview-textBlock-header'
        >
          {header}
        </div>
        <div
          className='ovex-at-ContractPreview-textBlock-text'
        >
          {text}
        </div>
      </div>
    );
  };

  return (
    <div
      className='ovex-at-ContractPreview'
    >
      <div
        className='ovex-at-ContractPreview-contractHeader'
      >
        <div
          className='ovex-at-ContractPreview-contractHeader-title'
        >
          {cp.title}
        </div>
        <div
          className='ovex-at-ContractPreview-contractHeader-subject'
        >
          {cp.subject}
        </div>
        <div
          className='ovex-at-ContractPreview-contractHeader-validFromText'
        >
          {cp.validFromText}
        </div>
      </div>
      <div
        className='ovex-at-ContractPreview-suppDeal'
      >
        {companyRenderer(cp.supplierData)}
        {companyRenderer(cp.dealerData)}
      </div>

      {textBlockRenderer(cp.agreementBaseHeading, cp.agreementBaseText)}

      <ContractAnnualTargetPreviewTable
        heading={cp.annualTargetData.heading}
        tableContent={cp.annualTargetData.tableContent}
        tableFooter={cp.annualTargetData.tableFooter}
        tableHeader={cp.annualTargetData.tableHeader}
      />
      <ContractAnnualTargetSalesPreviewTable
        heading={cp.annualTargetSalesData.heading}
        tableContent={cp.annualTargetSalesData.tableContent}
        tableFooter={cp.annualTargetSalesData.tableFooter}
        tableHeader={cp.annualTargetSalesData.tableHeader}
      />
      {existsBEV &&
      <ContractBEVAnnualTargetSalesPreviewTable
          heading={cp.annualTargetSalesBevData.heading}
          tableContent={cp.annualTargetSalesBevData.tableContent}
          tableHeader={cp.annualTargetSalesBevData.tableHeader}
      />
      }

      {textBlockRenderer(cp.agreementAdditionalHeading, cp.agreementAdditionalText)}
    </div>
  );
});

ContractPreview.propTypes = propTypes;
ContractPreview.defaultProps = defaultProps;

export default ContractPreview;
