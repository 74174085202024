import PropTypes from 'prop-types';
import React from 'react';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';
import { ProductionCorridorDTO } from '@ovex/pvt-web-api';

import { Page } from '../../../common/components';
import { LsiContext } from '../../../common/contexts';

import './DealerConfigRoc.scss';
import DealerConfig from '../DealerConfig/DealerConfig';

class DealerConfigRoc extends React.PureComponent {

  static contextType = LsiContext;

  static propTypes = {
    isFetching: PropTypes.bool,
    onGetProductionCorridorList: PropTypes.func.isRequired,
    onUpdateProductionCorridorBulk: PropTypes.func.isRequired,
    productionCorridorData: PropTypes.arrayOf(PropTypes.instanceOf(ProductionCorridorDTO))
  };

  static defaultProps = {
    isFetching: true,
    productionCorridorData: null
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentDidMount() {
    this.handleReloadProductionCorridor();
  }

  storeAlertRef = (ref) => {
    this.alert = ref;
  };

  handleReloadProductionCorridor = async () => {
    const { onGetProductionCorridorList } = this.props;

    try {
      await onGetProductionCorridorList();
    } catch (e) {
      const lsi = this.context;
      this.alert.addAlert({
        colorSchema: 'danger',
        content: lsi.getLSIItem('PVT.ERROR_MESSAGE.GET_PRODUCTION_CORRIDOR_FAILURE')
      });
    }
  };

  handleUpdateProductionCorridorBulk = async (changes) => {
    const lsi = this.context;
    const { onUpdateProductionCorridorBulk } = this.props;

    try {
      const response = await onUpdateProductionCorridorBulk(changes);

      this.alert.addAlert({ content: lsi.getLSIItem('PVT.ERROR_MESSAGE.UPDATE_PRODUCTION_CORRIDOR_SUCCESS') });
      // this.handleReloadProductionCorridor();

      return response;
    } catch (e) {
    }
  };

  render() {
    const lsi = this.context;
    const { isFetching, productionCorridorData } = this.props;
    return (
      <Page
        className={'ovex-roc-stock-dealer-control'}
        header={lsi.getLSIItem('PVT.PAGE_TITLE.DEALER_ROC')}
        loading={isFetching}
      >
        <DealerConfig
          attributeName={'annualTarget'}
          onReloadProductionCorridor={this.handleReloadProductionCorridor}
          onUpdateProductionCorridor={this.handleUpdateProductionCorridorBulk}
          pageDescription={{
            tableSpecification: lsi.getLSIItem('PVT.TABLE_HEADER.ANNUAL_TARGETS'),
            generalSettingDescription: lsi.getLSIItem('PVT.LABEL.GENERAL_SETTINGS.ROC'),
            legend: lsi.getLSIItem('PVT.LABEL.LEGEND'),
            legendDescriptionOn: lsi.getLSIItem('PVT.LABEL.LEGEND_DESCRIPTION_ON.ROC'),
            legendDescriptionOff: lsi.getLSIItem('PVT.LABEL.LEGEND_DESCRIPTION_OFF.ROC'),
            specificSettingDescription: lsi.getLSIItem('PVT.LABEL.SPECIFICATION_SETTINGS.ROC')
          }}
          productionCorridorData={productionCorridorData}>
        </DealerConfig>
        <Bricks.AlertBus
          hidden
          ref_={this.storeAlertRef}
        />
      </Page>
    );
  }
}

export default DealerConfigRoc;
