import {
  CancelNegotiationClosureFunctionalityContext,
  CreateAnnualTargetYearFunctionalityContext,
  CreateNegotiationClosureFunctionalityContext,
  CreateNegotiationFunctionalityContext,
  NegotiationShiftStateFunctionalityContext,
  UpdateActivatedNegotiationFunctionalityContext,
  UpdateAnnualTargetYearFunctionalityContext,
  UpdateNegotiationFunctionalityContext
} from '../../../privilege/context';
import { NegotiationStatusEnum, NegotiationTypeEnum } from '../../../utils/const';

import NegotiationRowType from './NegotiationRowType';

class NegotiationRow {

  /**
   * @param {Array.<module:model/AnnualTargetYearDTO>} annualTargetYearList
   * @return {Array.<NegotiationRow>}
   */
  static constructFromList = (annualTargetYearList) => {
    if (Array.isArray(annualTargetYearList) && annualTargetYearList.length > 0) {
      return annualTargetYearList.flatMap(aty => {
        const annualTargetYearRow = NegotiationRow.constructFromAnnualTargetYearDTO(aty);
        const negotiationRows = aty.negotiationList ? aty.negotiationList.map(n => NegotiationRow.constructFromNegotiationDetailDTO(n, annualTargetYearRow)) : [];
        return [
          annualTargetYearRow,
          ...negotiationRows
        ];
      });
    }
    return [];
  }

  /**
   * @param {module:model/AnnualTargetYearDTO|Object} data
   * @return {NegotiationRow}
   */
  static constructFromAnnualTargetYearDTO = (data) => {
    if (data) {
      const obj = new NegotiationRow();

      obj.rowType = NegotiationRowType.YEAR_SETTINGS;
      obj.id = data.hasOwnProperty('id') ? data.id : null;
      obj.version = data.hasOwnProperty('version') ? data.version : null;
      obj.year = data.hasOwnProperty('year') ? data.year : null;
      obj.coefficientFrom = data.hasOwnProperty('coefficientFrom') ? data.coefficientFrom : null;
      obj.coefficientTo = data.hasOwnProperty('coefficientFrom') ? data.coefficientTo : null;

      if (data.hasOwnProperty('atPeriodList')) {
        obj.atPeriodList = data.atPeriodList;
        obj.atPeriodMap = obj.atPeriodList.reduce(
          (map, atPeriod) => {
            map[atPeriod.unitOrder] = atPeriod;
            return map;
          },
          {}
        );
      }

      if (data.hasOwnProperty('atSalesPeriodList')) {
        obj.atSalesPeriodList = data.atSalesPeriodList;
        obj.atSalesPeriodMap = obj.atSalesPeriodList.reduce(
          (map, atSalesPeriod) => {
            map[atSalesPeriod.unitOrder] = atSalesPeriod;
            return map;
          },
          {}
        );
      }

      obj.periodCount = data.hasOwnProperty('periodCount') ? data.periodCount : null;
      obj.yearShiftList = data.hasOwnProperty('yearShiftList') ? data.yearShiftList : null;

      obj.modelGroupList = data.hasOwnProperty('modelGroupList') ? data.modelGroupList : null;

      obj.textMap = data.hasOwnProperty('textMap') ? data.textMap : null;

      obj.privilegeFunctionalityContextObject = {
        createAnnualTargetYearFnCtx: new CreateAnnualTargetYearFunctionalityContext(),
        createNegotiationContractFnCtx: new CreateNegotiationFunctionalityContext(obj.id, NegotiationTypeEnum.CONTRACT),
        createNegotiationAdditionFnCtx: new CreateNegotiationFunctionalityContext(obj.id, NegotiationTypeEnum.ADDITION),
        createNegotiationClosureFnCtx: new CreateNegotiationClosureFunctionalityContext(obj.id),
        updateAnnualTargetYearFnCtx: new UpdateAnnualTargetYearFunctionalityContext(obj.id),
        updateActivatedNegotiationFnCtx: new UpdateActivatedNegotiationFunctionalityContext(obj.id)
      };

      return obj;
    }
    return null;
  }

  /**
   * @param {module:model/NegotiationDetailDTO|Object} data
   * @param {NegotiationRow} rowParent - row data of year settings
   * @return {NegotiationRow}
   */
  static constructFromNegotiationDetailDTO = (data, rowParent) => {
    if (data) {
      const obj = new NegotiationRow();

      const negotiationType = data.hasOwnProperty('type') ? data.type : null;

      obj.rowType = NegotiationRowType.resolveRowTypeFromNegotiationType(negotiationType);

      obj.id = data.hasOwnProperty('id') ? data.id : null;
      obj.version = data.hasOwnProperty('version') ? data.version : null;
      obj.year = data.hasOwnProperty('year') ? data.year : null;
      obj.coefficientFrom = data.hasOwnProperty('coefficientFrom') ? data.coefficientFrom : null;
      obj.coefficientTo = data.hasOwnProperty('coefficientTo') ? data.coefficientTo : null;

      if (data.hasOwnProperty('atPeriodList')) {
        obj.atPeriodList = data.atPeriodList;
        obj.atPeriodMap = obj.atPeriodList.reduce(
          (map, atPeriod) => {
            map[atPeriod.unitOrder] = atPeriod;
            return map;
          },
          {}
        );
      }

      if (data.hasOwnProperty('atSalesPeriodList')) {
        obj.atSalesPeriodList = data.atSalesPeriodList;
        obj.atSalesPeriodMap = obj.atSalesPeriodList.reduce(
          (map, atSalesPeriod) => {
            map[atSalesPeriod.unitOrder] = atSalesPeriod;
            return map;
          },
          {}
        );
      }
      obj.periodCount = obj.atSalesPeriodList ? obj.atSalesPeriodList.length : 0;

      obj.modelGroupList = data.hasOwnProperty('modelGroupList') ? data.modelGroupList : null;

      obj.textMap = data.hasOwnProperty('textMap') ? data.textMap : null;

      obj.negotiationType = negotiationType;
      obj.name = data.hasOwnProperty('name') ? data.name : null;
      obj.status = data.hasOwnProperty('status') ? data.status : null;
      obj.proposalDealerFrom = data.hasOwnProperty('proposalDealerFrom') ? data.proposalDealerFrom : null;
      obj.proposalDealerTo = data.hasOwnProperty('proposalDealerTo') ? data.proposalDealerTo : null;
      obj.proposalApprovalFrom = data.hasOwnProperty('proposalApprovalFrom') ? data.proposalApprovalFrom : null;
      obj.proposalApprovalTo = data.hasOwnProperty('proposalApprovalTo') ? data.proposalApprovalTo : null;

      obj.dealerList = data.hasOwnProperty('dealerList') ? data.dealerList : null;

      obj.rowParent = rowParent;

      if (obj.isNegotiationContractOrAdditionRow()) {
        obj.privilegeFunctionalityContextObject = {
          updateNegotiationFnCtx: new UpdateNegotiationFunctionalityContext(obj.id),
          updateActivatedNegotiationFnCtx: new UpdateActivatedNegotiationFunctionalityContext(obj.id),
          negotiationShiftStateToActivatedFnCtx: new NegotiationShiftStateFunctionalityContext(obj.id)
        };
      } else if (obj.isNegotiationClosureRow()) {
        obj.privilegeFunctionalityContextObject = {
          cancelNegotiationClosureFnCtx: new CancelNegotiationClosureFunctionalityContext(obj.id)
        };
      }

      return obj;
    }
    return null;
  }

  isYearSettingsRow = () => {
    return this.rowType === NegotiationRowType.YEAR_SETTINGS;
  };
  isNegotiationsRow = () => {
    return this.rowType === NegotiationRowType.ADDITION
      || this.rowType === NegotiationRowType.CONTRACT
      || this.rowType === NegotiationRowType.CLOSURE;
  };
  isNegotiationContractOrAdditionRow = () => {
    return this.rowType === NegotiationRowType.ADDITION
      || this.rowType === NegotiationRowType.CONTRACT;
  };
  isNegotiationClosureRow = () => {
    return this.rowType === NegotiationRowType.CLOSURE;
  };
  isNegotiationActivated = () => {
    return this.status === NegotiationStatusEnum.ACTIVATED;
  }
  isNegotiationCreated = () => {
    return this.status === NegotiationStatusEnum.CREATED;
  }

}

NegotiationRow.prototype.rowType = undefined;
NegotiationRow.prototype.id = undefined;
NegotiationRow.prototype.version = undefined;
NegotiationRow.prototype.year = undefined;
NegotiationRow.prototype.coefficientFrom = undefined;
NegotiationRow.prototype.coefficientTo = undefined;

NegotiationRow.prototype.atPeriodList = undefined;
NegotiationRow.prototype.atPeriodMap = undefined;
NegotiationRow.prototype.atSalesPeriodList = undefined;
NegotiationRow.prototype.atSalesPeriodMap = undefined;
NegotiationRow.prototype.periodCount = undefined;
NegotiationRow.prototype.yearShiftList = undefined;

NegotiationRow.prototype.modelGroupList = undefined;

NegotiationRow.prototype.textMap = undefined;

NegotiationRow.prototype.negotiationType = undefined;
NegotiationRow.prototype.name = undefined;
NegotiationRow.prototype.status = undefined;
NegotiationRow.prototype.proposalDealerFrom = undefined;
NegotiationRow.prototype.proposalDealerTo = undefined;
NegotiationRow.prototype.proposalApprovalFrom = undefined;
NegotiationRow.prototype.proposalApprovalTo = undefined;

NegotiationRow.prototype.rowParent = undefined;

NegotiationRow.prototype.privilegeFunctionalityContextObject = undefined;

export default NegotiationRow;
