import {
  GetDealerContractPreviewFunctionalityContext,
  ShiftDealerContractWorkflowFunctionalityContext
} from '../../../privilege/context';
import { contractNameFormatter } from '../../../utils/formatters';
import { ContractStatusEnum } from '../../../utils/const';
import DealerModelGroupAnnualTargetConverter
  from '../DealerModelGroupAnnualTargetTable/DealerModelGroupAnnualTargetConverter';
import DealerPeriodAnnualTargetSalesConverter
  from '../DealerPeriodAnnualTargetSalesTable/DealerPeriodAnnualTargetSalesConverter';

class ContractSelectItem {

  /**
   * @param {ContractDetailDTO} contract
   * @param lsi
   */
  constructor(contract, lsi) {
    this.contract = contract;
    this.content = `${lsi.getLSIItem('ANNUAL_TARGET.ENUM_LABELS.CONTRACT_STATUS')[contract.status]} - ${contractNameFormatter(contract, lsi)}`;
    this.value = contract.id.toString();
  }

  /**
   *
   * @param {Array.<ContractDetailDTO>} contractList
   * @param lsi
   * @return {Array.<ContractSelectItem>}
   */
  static constructFromContractList = (contractList, lsi) => {
    return contractList == null
      ? []
      : contractList.map(contract => ContractSelectItem.constructFromContract(contract, lsi));
  };

  /**
   * @param {ContractDetailDTO} contract
   * @param lsi
   * @return {ContractSelectItem}
   */
  static constructFromContract = (contract, lsi) => {
    if (contract == null) {
      return null;
    }

    const item = new ContractSelectItem(contract, lsi);

    item.modelGroupAnnualTargetList = DealerModelGroupAnnualTargetConverter.fromList(item.contract.modelGroupList);
    item.periodAnnualTargetSalesList = DealerPeriodAnnualTargetSalesConverter.constructFromBranchList(item.contract.branchList);
    item.bevAnnualTargetSales = item.contract.bevAnnualTargetSales

    item.privilegeFunctionalityContextObject = {
      getDealerContractPreviewFnCtx: new GetDealerContractPreviewFunctionalityContext(item.contract.id),
      shiftDealerContractWorkflowFnCtx: new ShiftDealerContractWorkflowFunctionalityContext(item.contract.id, ContractStatusEnum.DIRECTOR_APPROVED)
    };

    return item;
  }

}

ContractSelectItem.prototype.contract = undefined;
// uuSelect properties
ContractSelectItem.prototype.content = undefined;
ContractSelectItem.prototype.value = undefined;

ContractSelectItem.prototype.modelGroupAnnualTargetList = undefined;
ContractSelectItem.prototype.periodAnnualTargetSalesList = undefined;
ContractSelectItem.prototype.bevAnnualTargetSales = undefined;

ContractSelectItem.prototype.privilegeFunctionalityContextObject = undefined;

export default ContractSelectItem;
