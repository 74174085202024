import PropTypes from 'prop-types';
import React from 'react';
import { NegotiationDealerDTO } from '@ovex/annual-target-web-api';

import { OvexAGTable } from '../../../../../common/components/ag-grid';
import { ListSelector } from '../../../../../common/components/forms';
import { LsiContext } from '../../../../../common/contexts';
import { NegotiationTypeEnum } from '../../../../utils/const';
import { isReadOnlyInput } from '../NegotiationModalInputRules';

import { COLUMN_IDS, columnTypes, getColumnDefinitionMap } from './NegotiationDealerListForm.columnDefs';

const dealerListSelectorItemMapper = (dealer) => {
  dealer.value = dealer.dealerNumber;
  return dealer;
};

const dealerWithMinBaseMapper = (dealer) => ({
  value: dealer.dealerNumber,
  dealerNumber: dealer.dealerNumber,
  dealerName: dealer.dealerName,
  minBase: dealer.minBase == null ? 0 : dealer.minBase,
  bev: dealer.bev
});

export const InputNames = {
  dealerList: 'dealerList'
};

const NegotiationDealerListFormComponent = (props, ref) => {
  const lsi = React.useContext(LsiContext);

  const readOnly = !props.onSave;

  const [ dealerListSelected, setDealerListSelected ] = React.useState(props.negotiationDealerList);

  React.useImperativeHandle(
    ref,
    () => ({
      isValid: () => {
        return true;
      },
      save: () => props.onSave && props.onSave({ formId: props.formId, values: dealerListSelected })
    })
  );

  const dealerListSelectorItems = React.useMemo(() => {
    const selectorItemList = dealerListSelected ? dealerListSelected.map(dealerListSelectorItemMapper) : [];
    props.dealerList && props.dealerList.forEach(dealer => {
      const dealerItem = selectorItemList.find(d => d.dealerNumber === dealer.dealerNumber);
      if (!dealerItem) {
        selectorItemList.push(dealerWithMinBaseMapper(dealer));
      }
    });
    return selectorItemList;
  }, [props.dealerList, dealerListSelected]);

  const dealerNumberListSelected = React.useMemo(() => dealerListSelected && dealerListSelected.map(d => d.dealerNumber), [dealerListSelected]);

  const dealerNameColumnDef = getColumnDefinitionMap[COLUMN_IDS.DEALER_NAME](lsi);
  const dealerNumberColumnDef = getColumnDefinitionMap[COLUMN_IDS.DEALER_NUMBER](lsi);

  if (isReadOnlyInput(props.editRules, InputNames.dealerList, readOnly)) {
    return (
      <OvexAGTable
        columnDefs={[
          dealerNameColumnDef,
          dealerNumberColumnDef,
          getColumnDefinitionMap[COLUMN_IDS.MIN_BASE_READ_ONLY](lsi, props.negotiationType),
          getColumnDefinitionMap[COLUMN_IDS.BEV](lsi, props.negotiationType, false)
        ]}
        columnTypes={columnTypes}
        height='300px'
        rowData={dealerListSelected}
      />
    );
  }

  return (
    <ListSelector
      columnDefAvailableOptions={[
        dealerNameColumnDef,
        dealerNumberColumnDef
      ]}
      columnDefSelectedOptions={[
        dealerNameColumnDef,
        dealerNumberColumnDef,
        getColumnDefinitionMap[COLUMN_IDS.MIN_BASE](lsi, props.negotiationType),
        getColumnDefinitionMap[COLUMN_IDS.BEV](lsi, props.negotiationType, true)
      ]}
      columnTypes={columnTypes}
      enableRangeSelection
      items={dealerListSelectorItems}
      onChange={setDealerListSelected}
      value={dealerNumberListSelected}
    />
  );
};

const NegotiationDealerListForm = React.memo(React.forwardRef(NegotiationDealerListFormComponent));

NegotiationDealerListFormComponent.propTypes = {
  dealerList: PropTypes.arrayOf(PropTypes.instanceOf(NegotiationDealerDTO)),
  editRules: PropTypes.arrayOf(PropTypes.string),
  formId: PropTypes.string.isRequired,
  negotiationDealerList: PropTypes.arrayOf(PropTypes.instanceOf(NegotiationDealerDTO)),
  negotiationType: PropTypes.oneOf(Object.keys(NegotiationTypeEnum)).isRequired,
  onSave: PropTypes.func
};

NegotiationDealerListFormComponent.defaultProps = {
  dealerList: null,
  editRules: null,
  negotiationDealerList: [],
  onSave: null
};

export default NegotiationDealerListForm;
