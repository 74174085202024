import { modulePathPrefix } from '../config/moduleConfig';

const modulePaths = {
  ROUTE_PATH_PVT_PRODUCTION_CORRIDOR: modulePathPrefix + '/production-corridor',
  ROUTE_PATH_PVT_RESTRICTIONS: modulePathPrefix + '/restrictions',
  ROUTE_PATH_PVT_IMPORTER_SWAP_COMMISSION_PVT: modulePathPrefix + '/importer/swap-commission-pvt',
  ROUTE_PATH_PVT_DEALER_SWAP_COMMISSION_PVT: modulePathPrefix + '/dealer/swap-commission-pvt',
  ROUTE_PATH_PVT_AVAILABLE_CAPACITY_DEALER: modulePathPrefix + '/available-capacity',
  ROUTE_PATH_PVT_DEALER_STOCK_CAR: modulePathPrefix + '/dealer-stock-car',
  ROUTE_PATH_PVT_DEALER_ROC: modulePathPrefix + '/dealer-roc'
};

export default modulePaths;