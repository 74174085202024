import React from 'react';
import PropTypes from 'prop-types';
import { NegotiationDealerDTO, ProductionCapacityRatioDTO } from '@ovex/annual-target-web-api';

import { Button, ButtonBar, Modal, Tabs } from '../../../../common/components';
import { LsiContext } from '../../../../common/contexts';
import { useAlertBusOvex } from '../../../../common/hooks';
import { AlertTypeEnum } from '../../../../common/objects';
import { NegotiationTypeEnum } from '../../../utils/const';
import YearSettingsTexts from '../AnnualTargetYearSettings/YearSettingsTexts/YearSettingsTexts';
import NegotiationRow from '../NegotiationsTable/NegotiationRow';
import { initializeNeg } from '../../../components/QuarterPeriods/QuarterComponentForm.utils';

import NegotiationTabs from './NegotiationTabs';
import NegotiationHeaderForm from './NegotiationHeaderForm/NegotiationHeaderForm';
import NegotiationDealerListForm from './NegotiationDealerListForm/NegotiationDealerListForm';
import NegotiationModelGroupListForm from './NegotiationModelGroupList/NegotiationModelGroupListForm';

import './NegotiationCreationModalForm.scss';

const propTypes = {
  className: PropTypes.string,
  dealerList: PropTypes.arrayOf(PropTypes.instanceOf(NegotiationDealerDTO)),
  editRules: PropTypes.arrayOf(PropTypes.string),
  negotiationType: PropTypes.oneOf(Object.keys(NegotiationTypeEnum)).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  productionCapacityRatioList: PropTypes.arrayOf(PropTypes.instanceOf(ProductionCapacityRatioDTO)),
  shown: PropTypes.bool,
  yearSettings: PropTypes.instanceOf(NegotiationRow).isRequired
};

const defaultProps = {
  className: null,
  dealerList: null,
  editRules: null,
  productionCapacityRatioList: null,
  shown: false
};

const NegotiationCreationModalForm = React.memo((props) => {
  const lsi = React.useContext(LsiContext);
  const { handleAddAlertSimple } = useAlertBusOvex();

  const classNames = ['ovex-NegotiationCreationModalForm'];
  props.className && classNames.push(props.className);

  const [rocForModels, setRocForModels] = React.useState(initializeNeg(props.yearSettings.atPeriodList, 'ROC', props.negotiationType));
  const [selectedTab, setSelectedTab] = React.useState(NegotiationTabs.header);

  const handleSetSelectedTab = React.useCallback(
    (opt) => setSelectedTab(opt.tab.props.name),
    []
  );

  const tabFormValues = React.useRef({});

  const headerFormRef = React.useRef(null);
  const dealersFormRef = React.useRef(null);
  const modelGroupsFormRef = React.useRef(null);

  const onSave = props.onSave;
  const handleSave = React.useCallback(() => {
    if (headerFormRef.current.isValid()) {
      headerFormRef.current.save();

      if (dealersFormRef.current.isValid()) {
        dealersFormRef.current.save();

        if (modelGroupsFormRef.current.isValid()) {
          modelGroupsFormRef.current.save();

          onSave({
            annualTargetYearId: props.yearSettings.id,
            type: props.negotiationType,
            header: tabFormValues.current[NegotiationTabs.header],
            dealerList: tabFormValues.current[NegotiationTabs.dealers],
            modelGroupList: tabFormValues.current[NegotiationTabs.modelGroups]
          });
        } else {
          setSelectedTab(NegotiationTabs.modelGroups);
        }
      } else {
        setSelectedTab(NegotiationTabs.dealers);
      }
    } else {
      setSelectedTab(NegotiationTabs.header);
    }
  }, [props.yearSettings.id, props.negotiationType, onSave]);

  const handleSaveTabFrom = React.useCallback(
    ({ formId, values }) => {
      tabFormValues.current[formId] = values;
    },
    []
  );

  const handleUpdateModels = (data) => {
    const initialRocForModel = {};
    rocForModels.forEach(rm => {
      initialRocForModel[rm.unitOrder] = rm.negotiable;
    });
    const changed = data.some(dat => !dat.negotiable && initialRocForModel[dat.unitOrder] !== dat.negotiable);
    if (changed) {
      handleAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.MODEL_NEGOTIATION_DELETED_VALUES', AlertTypeEnum.WARNING);
    }
    setRocForModels(data);
  };

  const getHeaderLabel = (negotiationType) => {
    if (negotiationType === NegotiationTypeEnum.ADDITION) {
      return lsi.getLSIItem('ANNUAL_TARGET.PAGE_TITLE.NEGOTIATION_ADDITIONS_NEW');
    } else if (negotiationType === NegotiationTypeEnum.CONTRACT) {
      return lsi.getLSIItem('ANNUAL_TARGET.PAGE_TITLE.NEGOTIATION_CONTRACTS_NEW');
    }
  };

  return (
    <Modal
      className={classNames.join(' ')}
      header={getHeaderLabel(props.negotiationType)}
      onClose={props.onClose}
      shown={props.shown}
      size={(selectedTab === NegotiationTabs.modelGroups || selectedTab === NegotiationTabs.dealers) ? 'xl' : 'l'}
    >
      <Tabs
        activeName={selectedTab}
        onChange={handleSetSelectedTab}
      >
        <Tabs.Item
          header={lsi.getLSIItem('ANNUAL_TARGET.TABS.NEGOTIATION_MODAL.HEADER')}
          name={NegotiationTabs.header}
        >
          <NegotiationHeaderForm
            atPeriodList={props.yearSettings.atPeriodList}
            atSalesPeriodList={props.yearSettings.atSalesPeriodList}
            coefficientFrom={props.yearSettings.coefficientFrom}
            coefficientTo={props.yearSettings.coefficientTo}
            creationMode
            editRules={props.editRules}
            formId={NegotiationTabs.header}
            negotiationType={props.negotiationType}
            onSave={handleSaveTabFrom}
            ref={headerFormRef}
            updateModels={handleUpdateModels}
            year={props.yearSettings.year}
          />
        </Tabs.Item>
        <Tabs.Item
          header={lsi.getLSIItem('ANNUAL_TARGET.TABS.NEGOTIATION_MODAL.DEALERS')}
          name={NegotiationTabs.dealers}
        >
          <NegotiationDealerListForm
            dealerList={props.dealerList}
            editRules={props.editRules}
            formId={NegotiationTabs.dealers}
            negotiationType={props.negotiationType}
            onSave={handleSaveTabFrom}
            ref={dealersFormRef}
          />
        </Tabs.Item>
        <Tabs.Item
          header={lsi.getLSIItem('ANNUAL_TARGET.TABS.NEGOTIATION_MODAL.MODELS')}
          name={NegotiationTabs.modelGroups}
        >
          <NegotiationModelGroupListForm
            atPeriodList={rocForModels}
            editRules={props.editRules}
            formId={NegotiationTabs.modelGroups}
            modelGroupList={props.yearSettings.modelGroupList}
            negotiationType={props.negotiationType}
            onSave={handleSaveTabFrom}
            productionCapacityRatioList={props.productionCapacityRatioList}
            ref={modelGroupsFormRef}
            yearCurrent={props.yearSettings.year}
            yearShiftList={props.yearSettings.yearShiftList}
          />
        </Tabs.Item>
        <Tabs.Item
          header={lsi.getLSIItem('ANNUAL_TARGET.TABS.NEGOTIATION_MODAL.TEXTS')}
          name={NegotiationTabs.texts}
        >
          <YearSettingsTexts
            formId={NegotiationTabs.texts}
            textMap={props.yearSettings.textMap}
          />
        </Tabs.Item>
      </Tabs>
      <ButtonBar
        align={ButtonBar.align.center}
        formControlsBar
      >
        <Button
          content={lsi.getLSIItem('ANNUAL_TARGET.BUTTON.CANCEL')}
          onClick={props.onClose}
          type={Button.type.SECONDARY}
        />
        <Button
          content={lsi.getLSIItem('ANNUAL_TARGET.BUTTON.SAVE')}
          onClick={handleSave}
          type={Button.type.PRIMARY}
        />
      </ButtonBar>
    </Modal>
  );
});

NegotiationCreationModalForm.propTypes = propTypes;
NegotiationCreationModalForm.defaultProps = defaultProps;

export default NegotiationCreationModalForm;
