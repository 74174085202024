import { NegotiationTypeEnum } from '../../../../utils/const';
import { isString, parseNumber } from '../../../../../common/utils/helpers';

export const COLUMN_IDS = {
  DEALER_NAME: 'dealerName',
  DEALER_NUMBER: 'dealerNumber',
  MIN_BASE_READ_ONLY: 'minBaseReadOnly',
  MIN_BASE: 'minBase',
  BEV: 'bev'
};

const COLUMN_TYPE_ENUM = {
  DEFAULT_CD: 'defaultDefinitionColumn'
};

export const columnTypes = {
  [COLUMN_TYPE_ENUM.DEFAULT_CD]: {
    sortable: true,
    filter: true,
    filterParams: { newRowsAction: 'keep' },
    menuTabs: ['filterMenuTab', 'generalMenuTab']
  }
};

const EDITABLE_NEGOTIATION_STATES = [NegotiationTypeEnum.ADDITION, NegotiationTypeEnum.CONTRACT];

export const getColumnDefinitionMap = {
  [COLUMN_IDS.DEALER_NAME]: (lsi) => ({
    colId: COLUMN_IDS.DEALER_NAME,
    valueGetter: 'data.dealerName',
    headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DEALER'),
    type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
    flex: 1
  }),
  [COLUMN_IDS.DEALER_NUMBER]: (lsi) => ({
    colId: COLUMN_IDS.DEALER_NUMBER,
    valueGetter: 'data.dealerNumber',
    headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.NUMBER'),
    type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
    width: 90
  }),
  [COLUMN_IDS.MIN_BASE_READ_ONLY]: (lsi, negotiationType) => ({
    colId: COLUMN_IDS.MIN_BASE_READ_ONLY,
    valueGetter: 'data.minBase',
    headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.MIN_BASE'),
    type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
    width: 140,
    hide: NegotiationTypeEnum.CLOSURE === negotiationType
  }),
  [COLUMN_IDS.MIN_BASE]: (lsi, negotiationType) => ({
    colId: COLUMN_IDS.MIN_BASE,
    valueGetter: 'data.minBase',
    headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.MIN_BASE_ABBR'),
    headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.MIN_BASE'),
    type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
    width: 90,
    editable: EDITABLE_NEGOTIATION_STATES.includes(negotiationType),
    valueParser: handleMinBaseValueParser,
    valueSetter: handleMinBaseValueSetter
  }),
  [COLUMN_IDS.BEV]: (lsi, negotiationType, editable) => ({
    colId: COLUMN_IDS.BEV,
    valueGetter: 'data.bev',
    headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.BEV'),
    headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.BEV'),
    hide: NegotiationTypeEnum.CLOSURE === negotiationType,
    type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
    width: 90,
    editable: EDITABLE_NEGOTIATION_STATES.includes(negotiationType) && editable,
    valueParser: handleBEVValueParser,
    valueSetter: handleBEVValueSetter
  })
};

const handleMinBaseValueParser = (valueParserParams) => {
  return /^[0-9]{1,6}$/.test(valueParserParams.newValue) ? parseNumber(valueParserParams.newValue) : null;
};

const handleMinBaseValueSetter = (valueSetterParams) => {
  if (isString(valueSetterParams.newValue)) {
    valueSetterParams.newValue = handleMinBaseValueParser(valueSetterParams);
  }
  if (valueSetterParams.newValue != null && valueSetterParams.newValue !== valueSetterParams.oldValue) {
    valueSetterParams.data.minBase = valueSetterParams.newValue;
    return true;
  }
  return false;
};

const handleBEVValueParser = (valueParserParams) => {
  return /^[0-9]{1,6}$/.test(valueParserParams.newValue) ? parseNumber(valueParserParams.newValue) : null;
};

const handleBEVValueSetter = (valueSetterParams) => {
  if (isString(valueSetterParams.newValue)) {
    valueSetterParams.newValue = handleMinBaseValueParser(valueSetterParams);
  }
  if (valueSetterParams.newValue !== valueSetterParams.oldValue) {
    valueSetterParams.data.bev = valueSetterParams.newValue;
    return true;
  }
  return false;
};