import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ProductionCorridorDTO } from '@ovex/pvt-web-api';

import { EXCEL_STYLES_IDS, OvexAGTable } from '../../../../../common/components/ag-grid';
import DealerConfigProductionCorridorButtonBar
  from '../DealerConfigProductionCorridorButtonBar/DealerConfigProductionCorridorButtonBar';

import CheckBoxRenderer from './CheckBoxRenderer/CheckBoxRenderer';

import './DealerConfigProductionConrridorTable.scss';

const propTypes = {
  attributeName: PropTypes.string.isRequired,
  onReloadProductionCorridor: PropTypes.func,
  onUpdateProductionCorridor: PropTypes.func,
  pageDescription: PropTypes.shape({
    generalSettingDescription: PropTypes.string,
    legend: PropTypes.string,
    legendDescriptionOff: PropTypes.string,
    legendDescriptionOn: PropTypes.string,
    specificSettingDescription: PropTypes.string,
    tableSpecification: PropTypes.string
  }),
  productionCorridorData: PropTypes.arrayOf(PropTypes.instanceOf(ProductionCorridorDTO))
};

const defaultProps = {
  onReloadProductionCorridor: undefined,
  onUpdateProductionCorridor: undefined,
  pageDescription: PropTypes.shape({
    generalSettingDescription: null,
    legend: null,
    legendDescriptionOff: null,
    legendDescriptionOn: null,
    specificSettingDescription: null,
    tableSpecification: null
  }),
  productionCorridorData: []
};

const DealerConfigProductionCorridorTable = (props) => {
  const { editable, pageDescription, attributeName, productionCorridorData } = props;
  const { onSetEditableProductionCorridor, onReloadProductionCorridor, onUpdateProductionCorridor } = props;

  const [changedModelGroups, setChangedModelGroups] = React.useState([]);
  const [transposedData, setTransposedData] = React.useState([]);

  const columnDefs = (editable => {
    let newVar = productionCorridorData
      .map((item) => ({
        headerName: item.modelGroup,
        field: item.modelGroup,
        headerClass: 'pc-header-cell',
        menuTabs: [],
        cellClass: EXCEL_STYLES_IDS.STRING,
        cellRendererFramework: (params) => CheckBoxRenderer(params, editable),
        cellRendererParams: {
          onChange: (params) => {
            const value = params;
            const updatedData = [...transposedData];
            updatedData[0][item.modelGroup] = value;
            setTransposedData(updatedData);
            setChangedModelGroups((prevState) => {
              if (prevState.some(changedModelGroup => changedModelGroup.modelGroup === item.modelGroup)) {
                return prevState.filter((existingItem) => existingItem.modelGroup !== item.modelGroup);
              } else {
                return [...prevState, { ...item, [attributeName]: value }];
              }
            });
          }
        },
        valueGetter: (params) => {
          const rowData = params.data;
          return !!rowData[item.modelGroup];
        },
        width: 80
      }));
    const description = [{
      menuTabs: [],
      valueGetter: () => pageDescription.tableSpecification
    }];
    return [...description, ...newVar];
  });

  const rowData = useMemo(() => {
    const row = {};
    productionCorridorData.forEach((item) => {
      row[item.modelGroup] = item[attributeName];
    });
    setTransposedData([row]);
    return [row];
  }, [props.productionCorridorData]);

  return (
    <Fragment>
      <DealerConfigProductionCorridorButtonBar
        editable={editable}
        changedModelGroups={changedModelGroups}
        onReloadProductionCorridor={onReloadProductionCorridor}
        onUpdateProductionCorridor={onUpdateProductionCorridor}
        pageDescription={pageDescription}
        setEditable={onSetEditableProductionCorridor}
        setChangedModelGroups={setChangedModelGroups}
      />
      <OvexAGTable
        className={'ovex-dealer-config-production-corridor-table'}
        columnDefs={columnDefs(editable)}
        domLayout='autoHeight'
        processCellFromClipboard={(params) => {
          console.log(params);
        }}
        rowData={rowData}
        rowDataChangeDetectionStrategy="IdentityCheck"
        suppressRowClickSelection
      />
    </Fragment>
  );
};

DealerConfigProductionCorridorTable.defaultProps = defaultProps;
DealerConfigProductionCorridorTable.propTypes = propTypes;

export default DealerConfigProductionCorridorTable;