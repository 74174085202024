import React from 'react';
import PropTypes from 'prop-types';
import {
  AnnualTargetSalesBevPreviewTableContentDTO,
  AnnualTargetSalesBevPreviewTableHeaderDTO
} from '@ovex/annual-target-web-api';

import { OvexAGTable } from '../../../../common/components/ag-grid';

const propTypes = {
  heading: PropTypes.string.isRequired,
  tableContent: PropTypes.arrayOf(PropTypes.instanceOf(AnnualTargetSalesBevPreviewTableContentDTO)).isRequired,
  tableHeader: PropTypes.instanceOf(AnnualTargetSalesBevPreviewTableHeaderDTO).isRequired
};

const defaultProps = {};

const agDefaultColDef = {
  suppressMovable: true,
  menuTabs: []
};

const ContractBEVAnnualTargetSalesPreviewTable = (props) => {

  const columnDefs = React.useMemo(() => ([
    {
      headerName: props.tableHeader.annualTargetNumberLabel,
      valueGetter: 'data && data.annualTargetNumberLabel',
      width: 594,
      cellStyle: { 'font-weight': 'bold' }
    },
    {
      headerName: props.tableHeader.annualTargetNumber,
      valueGetter: 'data && data.annualTargetNumber',
      width: 120,
      cellStyle: { 'font-weight': 'bold' },
      type: 'numericColumn'
    }
  ]),
  [props.tableHeader]
  );

  return (
    <div
      className='ovex-at-ContractPreview-tableBlock'
    >
      <div
        className='ovex-at-ContractPreview-tableBlock-header'
      >
        {props.heading}
      </div>
      <div
        className='ovex-at-ContractPreview-tableBlock-table'
      >
        <OvexAGTable
          columnDefs={columnDefs}
          defaultColDef={agDefaultColDef}
          domLayout='print'
          rowData={props.tableContent}
        />
      </div>
    </div>
  );
};

ContractBEVAnnualTargetSalesPreviewTable.propTypes = propTypes;
ContractBEVAnnualTargetSalesPreviewTable.defaultProps = defaultProps;

export default ContractBEVAnnualTargetSalesPreviewTable;
