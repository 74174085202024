import React from 'react';
import PropTypes from 'prop-types';
import { Bricks } from 'uu5g04';
import { BevAnnualTargetSalesDTO, ContractDetailDTO } from '@ovex/annual-target-web-api';

import { OvexAGTable } from '../../../../common/components/ag-grid';
import { LsiContext } from '../../../../common/contexts';

import { columnTypes, getColumnDefinitions } from './DealerBEVAnnualTargetSalesTable.columnDefs';
import { handleGetContextMenuItems } from './DealerBEVAnnualTargetSalesTable.contextMenu';

import './DealerBEVAnnualTargetSalesTable.scss';

const propTypes = {
  bevAnnualTargetSales: PropTypes.instanceOf(BevAnnualTargetSalesDTO),
  className: PropTypes.string,
  contract: PropTypes.instanceOf(ContractDetailDTO),
  gridOptions: PropTypes.object,
  onShowCommissionListWithParams: PropTypes.func
};

const defaultProps = {
  bevAnnualTargetSales: null,
  className: null,
  contract: null,
  gridOptions: null,
  onShowCommissionListWithParams: undefined
};

const DealerBEVAnnualTargetSalesTable = React.memo(React.forwardRef((props, ref) => {
  const lsi = React.useContext(LsiContext);
  const tableRef = React.useRef(null);

  const classNames = ['ovex-DealerBEVAnnualTargetSalesTable'];
  props.className && classNames.push(props.className);

  const columnDefs = React.useMemo(
    () => getColumnDefinitions(lsi),
    [lsi]
  );

  const popupParent = React.useMemo<HTMLElement | null>(() => {
    return document.querySelector('body');
  }, []);

  return (
    <Bricks.Div
      className={classNames.join(' ')}
    >
      <OvexAGTable
        agContext={{
          onShowCommissionListWithParams: props.onShowCommissionListWithParams,
          statisticsRequestId: props.contract?.statisticsRequestId,
          statisticsType: props.contract?.statisticsType,
          negotiationType: props.contract?.negotiation.type,
          contractId: props.contract?.id
        }}
        alwaysShowVerticalScroll
        animateRows
        columnDefs={columnDefs}
        columnTypes={columnTypes}
        domLayout='autoHeight'
        enableRangeSelection
        getContextMenuItems={handleGetContextMenuItems}
        gridOptions={props.gridOptions}
        groupHeaderHeight={0}
        height='240px'
        popupParent={popupParent}
        ref={tableRef}
        rowData={props.bevAnnualTargetSales}
        rowDataChangeDetectionStrategy='IdentityCheck'
        suppressMovableColumns
      />
    </Bricks.Div>
  );
}));

DealerBEVAnnualTargetSalesTable.propTypes = propTypes;
DealerBEVAnnualTargetSalesTable.defaultProps = defaultProps;

export default DealerBEVAnnualTargetSalesTable;
