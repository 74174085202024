import { connect } from 'react-redux';

import { handleGetProductionCorridorList, handleUpdateProductionCorridorBulk } from '../../redux/modules/actions';

import DealerConfigStockCar from './DealerConfigStockCar';

const mapStateToProps = state => ({
  isFetching: state.pvt.productionCorridor.isFetching,
  productionCorridorData: state.pvt.productionCorridor.productionCorridorData,
});

const mapDispatchToProps = dispatch => ({
  onGetProductionCorridorList: () => dispatch(handleGetProductionCorridorList()),
  onUpdateProductionCorridorBulk: (productionCorridorBulkUpdate) => dispatch(handleUpdateProductionCorridorBulk(productionCorridorBulkUpdate))
});

export default connect(mapStateToProps, mapDispatchToProps)(DealerConfigStockCar);