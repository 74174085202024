import React from 'react';

import { ModuleRoutes, ProtectedRoute } from '../../common/components/routes';
import moduleContextData from '../config/moduleContextData';
import {
  AvailableCapacity,
  DealerConfigRoc,
  DealerConfigStockCar,
  ProductionCorridor,
  Restrictions,
  SwapCommissionPvtDealer,
  SwapCommissionPvtImporter
} from '../layouts';
import {
  ACGetDealerRestrictionListFunctionalityContext,
  PCGetProductionCorridorListFunctionalityContext,
  QRGetRestrictionListFunctionalityContext,
  SCPGetCommissionsSwapDealerFunctionalityContext,
  SCPGetCommissionsSwapImporterFunctionalityContext
} from '../privilege/context';

import modulePaths from './modulePaths';

const propTypes = {};
const defaultProps = {};

const pcGetProductionCorridorListFunctionalityContext = new PCGetProductionCorridorListFunctionalityContext();
const qrGetRestrictionListFunctionalityContext = new QRGetRestrictionListFunctionalityContext();
const scpGetCommissionsSwapDealerFunctionalityContext = new SCPGetCommissionsSwapDealerFunctionalityContext();
const scpGetCommissionsSwapImporterFunctionalityContext = new SCPGetCommissionsSwapImporterFunctionalityContext();
const acGetDealerRestrictionListFunctionalityContext = new ACGetDealerRestrictionListFunctionalityContext();

const PvtRoutes = () => {

  return (
    <ModuleRoutes moduleContextData={moduleContextData} >
      <ProtectedRoute
        path={modulePaths.ROUTE_PATH_PVT_PRODUCTION_CORRIDOR}
        privilegeFunctionalityContext={pcGetProductionCorridorListFunctionalityContext}
      >
        <ProductionCorridor />
      </ProtectedRoute>
      <ProtectedRoute
        path={modulePaths.ROUTE_PATH_PVT_DEALER_ROC}
        privilegeFunctionalityContext={pcGetProductionCorridorListFunctionalityContext}
      >
        <DealerConfigRoc/>
      </ProtectedRoute>
      <ProtectedRoute
        path={modulePaths.ROUTE_PATH_PVT_DEALER_STOCK_CAR}
        privilegeFunctionalityContext={pcGetProductionCorridorListFunctionalityContext}
      >
        <DealerConfigStockCar/>
      </ProtectedRoute>
      <ProtectedRoute
        path={modulePaths.ROUTE_PATH_PVT_RESTRICTIONS}
        privilegeFunctionalityContext={qrGetRestrictionListFunctionalityContext}
      >
        <Restrictions />
      </ProtectedRoute>
      <ProtectedRoute
        path={modulePaths.ROUTE_PATH_PVT_IMPORTER_SWAP_COMMISSION_PVT}
        privilegeFunctionalityContext={scpGetCommissionsSwapImporterFunctionalityContext}
      >
        <SwapCommissionPvtImporter />
      </ProtectedRoute>
      <ProtectedRoute
        path={modulePaths.ROUTE_PATH_PVT_DEALER_SWAP_COMMISSION_PVT}
        privilegeFunctionalityContext={scpGetCommissionsSwapDealerFunctionalityContext}
      >
        <SwapCommissionPvtDealer />
      </ProtectedRoute>
      <ProtectedRoute
        path={modulePaths.ROUTE_PATH_PVT_AVAILABLE_CAPACITY_DEALER}
        privilegeFunctionalityContext={acGetDealerRestrictionListFunctionalityContext}
      >
        <AvailableCapacity/>
      </ProtectedRoute>
    </ModuleRoutes>
  );
};

PvtRoutes.propTypes = propTypes;
PvtRoutes.defaultProps = defaultProps;

export default PvtRoutes;