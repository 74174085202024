import React from 'react';
import PropTypes from 'prop-types';
import { Bricks } from 'uu5g04';
import { BevAnnualTargetSalesDTO } from '@ovex/annual-target-web-api';

import { OvexAGTable } from '../../../../common/components/ag-grid';
import { LsiContext } from '../../../../common/contexts';
import { COLUMN_IDS } from '../utils/tableBaseColumnDefs';
import ContractRow from '../DealerContractsTable/ContractRow';

import { columnTypes, getColumnDefinitions } from './BEVAnnualTargetSalesTable.columns';
import { handleGetContextMenuItems } from './BEVAnnualTargetSalesTable.contextMenu';

import './BEVAnnualTargetSalesTable.scss';

const propTypes = {
  bevAnnualTargetSales: PropTypes.instanceOf(BevAnnualTargetSalesDTO),
  contract: PropTypes.instanceOf(ContractRow),
  detailGridId: PropTypes.string.isRequired,
  gridOptions: PropTypes.object.isRequired,
  masterGridApi: PropTypes.object.isRequired, // ag-Grid api object
  onGridSizeChanged: PropTypes.func.isRequired,
  onShowCommissionListWithParams: PropTypes.func.isRequired
};

const defaultProps = {
  bevAnnualTargetSales: null,
  contract: null
};

const BEVAnnualTargetSalesTable = React.memo((props) => {

  const lsi = React.useContext(LsiContext);

  const detailGridId = props.detailGridId;

  const ref = React.useRef();
  const onGridReady = (params) => {
    ref.current = params;

    const gridInfo = {
      id: detailGridId,
      api: params.api,
      columnApi: params.columnApi
    };
    props.masterGridApi.addDetailGridInfo(detailGridId, gridInfo);
  };

  React.useEffect(
    () => {
      return () => {
        props.masterGridApi.removeDetailGridInfo(detailGridId);
      };
    },
    [detailGridId, props.masterGridApi]
  );

  React.useEffect(() => {
    ref.current && ref.current.api.refreshCells({
      columns: [COLUMN_IDS.AT_ATS_RECOMMENDED_NUMBER],
      force: true
    });
  }, [props.editable]
  );

    const popupParent = React.useMemo<HTMLElement | null>(() => {
        return document.querySelector('body');
    }, []);


    return (
    <Bricks.Div className='ovex-BEVAnnualTargetSalesTable'>
      <OvexAGTable
        agContext={{
          onShowCommissionListWithParams: props.onShowCommissionListWithParams,
          statisticsRequestId: props.contract?.statisticsRequestId,
          statisticsType: props.contract?.statisticsType,
          negotiationType: props.contract?.negotiation.type,
          contractId: props.contract?.id
        }}
        animateRows
        columnDefs={getColumnDefinitions(lsi)}
        columnTypes={columnTypes}
        domLayout='autoHeight'
        enableRangeSelection
        getContextMenuItems={handleGetContextMenuItems}
        gridOptions={props.gridOptions}
        groupHeaderHeight={0}
        highlightLeafOddEvenRows
        onGridReady={onGridReady}
        onGridSizeChanged={props.onGridSizeChanged}
        popupParent={popupParent}
        rowData={props.bevAnnualTargetSales && [props.bevAnnualTargetSales]}
      />
    </Bricks.Div>
  );
});

BEVAnnualTargetSalesTable.propTypes = propTypes;
BEVAnnualTargetSalesTable.defaultProps = defaultProps;

export default BEVAnnualTargetSalesTable;
