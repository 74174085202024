import { mdiTextBoxOutline } from '@mdi/js';
import { CommissionLoadParamsDTO } from '@ovex/annual-target-web-api';

import { handleGetDefaultContextMenuItems, MenuItemDefBuilder } from '../../../../common/components/ag-grid';
import { COLUMN_IDS, COMMISSION_LIST_ATS_AVAILABLE_COLUMN_IDS } from '../utils/tableBaseColumnDefs';
import { AggregationTypeEnum } from '../../../utils/const';

export const handleGetContextMenuItems = (getContextMenuItemsParams ) => {
  return [
    DealerBEVAnnualTargetSalesMenuItems.getShowCommissionListItem(getContextMenuItemsParams),
    'separator',
    ...handleGetDefaultContextMenuItems(getContextMenuItemsParams)
  ];
};

const DealerBEVAnnualTargetSalesMenuItems = {
  getShowCommissionListItem: (getContextMenuItemsParams) => {
    const { column, context } = getContextMenuItemsParams;
    const { onShowCommissionListWithParams, statisticsRequestId, statisticsType, negotiationType, contractId } = context;

    const colId = column.getColId();
    const isAvailable = onShowCommissionListWithParams != null
      && COMMISSION_LIST_ATS_AVAILABLE_COLUMN_IDS.includes(colId);

    if (!isAvailable) {
      return null;
    }

    return new MenuItemDefBuilder(getContextMenuItemsParams, 'ANNUAL_TARGET.CONTEXT_MENU.SHOW_COMMISSION_LIST')
      .iconFromPath(mdiTextBoxOutline)
      .action(() => {
        const params = new CommissionLoadParamsDTO(_getAggregationTypeList(colId), statisticsRequestId, statisticsType, negotiationType);
        params.contractId = contractId;

        onShowCommissionListWithParams(params);
      })
      .build();
  }
};

const _getAggregationTypeList = (colId) => {
  switch (colId) {
    case COLUMN_IDS.STATISTICS_DONE_NUMBER:
      return [AggregationTypeEnum.ATS_BEV_SOLD];
    default:
  }
};
