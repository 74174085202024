import PropTypes from 'prop-types';
import React from 'react';
import {
  ActivatedNegotiationUpdateDTO,
  AnnualTargetYearNewDTO,
  AnnualTargetYearUpdateDTO,
  ModelGroupDTO,
  NegotiationATPeriodNewDTO,
  NegotiationATPeriodUpdateDTO,
  NegotiationATSalesPeriodNewDTO,
  NegotiationATSalesPeriodUpdateDTO,
  NegotiationDealerDTO,
  NegotiationModelNewDTO,
  NegotiationModelUpdateDTO,
  NegotiationNewDTO,
  NegotiationUpdateDTO,
  NegotiationWorkflowDTO,
  ProductionCapacityRatioDTO
} from '@ovex/annual-target-web-api';

import { Page } from '../../../common/components';
import { LsiContext } from '../../../common/contexts';
import { AlertTypeEnum } from '../../../common/objects';
import { isEmptyArray } from '../../../common/utils/arrays';

import { handleEditableInputs } from './NegotiationModalFrom/NegotiationModalInputRules';
import NegotiationsTable from './NegotiationsTable/NegotiationsTable';
import { YearSettingsCreationModalForm, YearSettingsModalForm } from './AnnualTargetYearSettings';
import { NegotiationCreationModalForm, NegotiationModalForm } from './NegotiationModalFrom';
import NegotiationRow from './NegotiationsTable/NegotiationRow';

import './Negotiations.scss';

class Negotiations extends React.PureComponent {

  static contextType = LsiContext;

  static propTypes = {
    calendarYearList: PropTypes.arrayOf(PropTypes.number),
    createAnnualTargetYear: PropTypes.func.isRequired,
    createNegotiation: PropTypes.func.isRequired,
    dealerList: PropTypes.arrayOf(PropTypes.instanceOf(NegotiationDealerDTO)),
    getAnnualTargetCalendarYears: PropTypes.func,
    getAnnualTargetYears: PropTypes.func.isRequired,
    getModelGroups: PropTypes.func.isRequired,
    getNegotiationDealers: PropTypes.func.isRequired,
    getProductionCapacityRatio: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    modelGroupList: PropTypes.arrayOf(PropTypes.instanceOf(ModelGroupDTO)),
    negotiationList: PropTypes.arrayOf(PropTypes.instanceOf(NegotiationRow)),
    onAddAlertSimple: PropTypes.func.isRequired,
    onCancelNegotiationClosure: PropTypes.func.isRequired,
    onCleanModelGroups: PropTypes.func.isRequired,
    onCleanNegotiationDealers: PropTypes.func.isRequired,
    onCleanProductionCapacityRatio: PropTypes.func.isRequired,
    onCreateNegotiationClosure: PropTypes.func.isRequired,
    onUpdateAnnualTargetYears: PropTypes.func.isRequired,
    productionCapacityRatioList: PropTypes.arrayOf(PropTypes.instanceOf(ProductionCapacityRatioDTO)),
    shiftNegotiationWorkflow: PropTypes.func.isRequired,
    updateActivatedNegotiation: PropTypes.func.isRequired,
    updateNegotiation: PropTypes.func.isRequired
  };

  static defaultProps = {
    calendarYearList: null,
    dealerList: null,
    getAnnualTargetCalendarYears: null,
    isFetching: true,
    modelGroupList: null,
    negotiationList: null,
    productionCapacityRatioList: null
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      negotiationCreationModalFormProps: {
        shown: false,
        yearSettingsData: null,
        negotiationType: null
      },
      negotiationModalFormProps: {
        shown: false,
        negotiationData: null,
        readOnly: true
      },
      yearSettingsCreationModalFormProps: {
        shown: false
      },
      yearSettingsModalFormProps: {
        shown: false,
        yearSettingsData: null,
        readOnly: true
      }
    };
  }

  componentDidMount() {
    this.handleReloadAnnualTargetYears();
  }

  handleReloadAnnualTargetYears = async () => {
    const { getAnnualTargetYears, onAddAlertSimple } = this.props;

    await this.handleLoadAnnualTargetCalendarYears();

    try {
      await getAnnualTargetYears();
    } catch (e) {
      onAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.GET_ANNUAL_TARGET_YEARS_FAILURE', AlertTypeEnum.WARNING);
    }
  };

  handleCleanProductionCapacityRatio = () => {
    const { onCleanProductionCapacityRatio } = this.props;
    onCleanProductionCapacityRatio();
  };

  handleLoadProductionCapacityRatioForNegotiation = async (year) => {
    const { getProductionCapacityRatio, onAddAlertSimple } = this.props;

    try {
      await getProductionCapacityRatio(year);
    } catch (e) {
      onAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.GET_PRODUCTION_CAPACITY_FAILURE', AlertTypeEnum.WARNING);
    }
  };

  handleCleanNegotiationDealers = () => {
    const { onCleanNegotiationDealers } = this.props;
    onCleanNegotiationDealers();
  };

  handleLoadAvailableDealersForNegotiation = async (year, negotiationType) => {
    const { getNegotiationDealers, onAddAlertSimple } = this.props;

    try {
      await getNegotiationDealers(year, negotiationType);
    } catch (e) {
      onAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.GET_NEGOTIATION_DEALERS_FAILURE', AlertTypeEnum.WARNING);
    }
  };

  handleCleanModelGroups = () => {
    const { onCleanModelGroups } = this.props;
    onCleanModelGroups();
  };

  handleLoadModelGroups = async () => {
    const { getModelGroups, onAddAlertSimple } = this.props;

    try {
      await getModelGroups();
    } catch (e) {
      onAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.GET_MODEL_GROUPS_FAILURE', AlertTypeEnum.WARNING);
    }
  };

  handleLoadAnnualTargetCalendarYears = async () => {
    const { getAnnualTargetCalendarYears, onAddAlertSimple } = this.props;

    if (getAnnualTargetCalendarYears) {
      try {
        await getAnnualTargetCalendarYears();
      } catch (e) {
        onAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.GET_CALENDAR_YEARS_FAILURE', AlertTypeEnum.WARNING);
      }
    }
  };

  handleShowNegotiationCreationModalForm = (params) => {
    this.handleLoadAvailableDealersForNegotiation(params.yearSettingsData.year, params.negotiationType);
    this.handleLoadProductionCapacityRatioForNegotiation(params.yearSettingsData.year);
    this.setState({
      negotiationCreationModalFormProps: {
        shown: true,
        yearSettingsData: params.yearSettingsData,
        negotiationType: params.negotiationType,
        editRules: handleEditableInputs()
      }
    });
  };

  handleHideNegotiationCreationModalForm = () => {
    this.handleCleanNegotiationDealers();
    this.handleCleanProductionCapacityRatio();
    this.setState({
      negotiationCreationModalFormProps: {
        shown: false,
        yearSettingsData: null,
        negotiationType: null
      }
    });
  };

  handleShowNegotiationDetailModalForm = (negotiationRowData) => {
    this.setState({
      negotiationModalFormProps: {
        shown: true,
        negotiationData: negotiationRowData,
        readOnly: true
      }
    });
  };

  handleShowNegotiationUpdateModalForm = (negotiationRowData) => {
    this.handleLoadAvailableDealersForNegotiation(negotiationRowData.year, negotiationRowData.negotiationType);
    this.handleLoadProductionCapacityRatioForNegotiation(negotiationRowData.year);
    this.setState({
      negotiationModalFormProps: {
        shown: true,
        negotiationData: negotiationRowData,
        readOnly: false,
        editRules: handleEditableInputs(negotiationRowData)
      }
    });
  };

  handleHideNegotiationModalForm = () => {
    this.handleCleanNegotiationDealers();
    this.handleCleanProductionCapacityRatio();
    this.handleReloadAnnualTargetYears();
    this.setState({
      negotiationModalFormProps: {
        shown: false,
        negotiationData: null,
        readOnly: true
      }
    });
  };

  handleCreateNegotiation = async (formData) => {
    const { createNegotiation, onAddAlertSimple } = this.props;

    try {
      const data = {
        annualTargetYearId: formData.annualTargetYearId,
        type: formData.type,
        name: formData.header.name,
        proposalDealerFrom: formData.header.proposalDealerFrom,
        proposalDealerTo: formData.header.proposalDealerTo,
        proposalApprovalFrom: formData.header.proposalApprovalFrom,
        proposalApprovalTo: formData.header.proposalApprovalTo,
        dealerList: formData.dealerList,
        modelGroupList: formData.modelGroupList.map(mg => new NegotiationModelNewDTO(mg.id, mg.productionCapacityATPeriodList, mg.negotiable)),
        atSalesPeriodList: formData.header.atSalesPeriodList.map(p => new NegotiationATSalesPeriodNewDTO(p.id, p.negotiable)),
        atPeriodList: formData.header.atPeriodList.map(p => new NegotiationATPeriodNewDTO(p.id, p.negotiable, p.correction))
      };

      const response = await createNegotiation(NegotiationNewDTO.constructFromObject(data));

      onAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.CREATE_NEGOTIATIONS_SUCCESS');
      this.handleHideNegotiationCreationModalForm();
      this.handleReloadAnnualTargetYears();

      return response;
    } catch (e) {
      onAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.CREATE_NEGOTIATIONS_FAILURE', AlertTypeEnum.WARNING);
    }
  };

  handleCreateNegotiationClosure = async (formData) => {
    const { onCreateNegotiationClosure, onAddAlertSimple } = this.props;

    try {
      const response = await onCreateNegotiationClosure(formData.atYearId);

      onAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.CREATE_NEGOTIATIONS_CLOSURE_SUCCESS');

      this.handleReloadAnnualTargetYears();

      return response;
    } catch (e) {
      onAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.CREATE_NEGOTIATIONS_CLOSURE_FAILURE', AlertTypeEnum.WARNING);
    }
  };

  handleCancelNegotiationClosure = async (formData) => {
    const { onCancelNegotiationClosure, onAddAlertSimple } = this.props;

    try {
      const response = await onCancelNegotiationClosure(formData.negotiationId);

      onAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.CANCEL_NEGOTIATIONS_CLOSURE_SUCCESS');

      this.handleReloadAnnualTargetYears();

      return response;
    } catch (e) {
      onAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.CANCEL_NEGOTIATIONS_CLOSURE_FAILURE', AlertTypeEnum.WARNING);
    }
  };

  handleUpdateNegotiation = async (formData) => {
    const { updateNegotiation, onAddAlertSimple } = this.props;

    try {
      const data = {
        id: formData.negotiationId,
        name: formData.header.name,
        proposalDealerFrom: formData.header.proposalDealerFrom,
        proposalDealerTo: formData.header.proposalDealerTo,
        proposalApprovalFrom: formData.header.proposalApprovalFrom,
        proposalApprovalTo: formData.header.proposalApprovalTo,
        dealerList: formData.dealerList,
        modelGroupList: formData.modelGroupList.map(mg => new NegotiationModelUpdateDTO(mg.id, mg.productionCapacityATPeriodList, mg.negotiable)),
        atSalesPeriodList: formData.header.atSalesPeriodList.map(p => new NegotiationATSalesPeriodUpdateDTO(p.id, p.negotiable)),
        atPeriodList: formData.header.atPeriodList.map(p => new NegotiationATPeriodUpdateDTO(p.id, p.negotiable, p.correction))
      };
      const response = await updateNegotiation(NegotiationUpdateDTO.constructFromObject(data));

      onAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.UPDATE_NEGOTIATIONS_SUCCESS');
      this.handleHideNegotiationModalForm();
      this.handleReloadAnnualTargetYears();

      return response;
    } catch (e) {
      onAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.UPDATE_NEGOTIATIONS_FAILURE', AlertTypeEnum.WARNING);
    }
  };
  handleUpdateActivatedNegotiation = async (formData) => {
    const { updateActivatedNegotiation, onAddAlertSimple } = this.props;

    try {
      const data = {
        id: formData.negotiationId,
        name: formData.header.name,
        proposalDealerFrom: formData.header.proposalDealerFrom,
        proposalDealerTo: formData.header.proposalDealerTo,
        proposalApprovalFrom: formData.header.proposalApprovalFrom,
        proposalApprovalTo: formData.header.proposalApprovalTo
      };
      const response = await updateActivatedNegotiation(ActivatedNegotiationUpdateDTO.constructFromObject(data));

      onAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.UPDATE_NEGOTIATIONS_SUCCESS');
      this.handleHideNegotiationModalForm();
      this.handleReloadAnnualTargetYears();

      return response;
    } catch (e) {
      onAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.UPDATE_NEGOTIATIONS_FAILURE', AlertTypeEnum.WARNING);
    }
  };

  handleShiftNegotiationWorkflow = async (formData) => {
    const { shiftNegotiationWorkflow, onAddAlertSimple } = this.props;

    try {
      await shiftNegotiationWorkflow(NegotiationWorkflowDTO.constructFromObject(formData));
      onAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.SHIFT_WF_NEGOTIATIONS_SUCCESS');
      this.handleReloadAnnualTargetYears();
    } catch (e) {
      onAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.SHIFT_WF_NEGOTIATIONS_FAILURE', AlertTypeEnum.WARNING);
    }
  };

  handleShowYearSettingsCreationModalForm = () => {
    this.handleLoadModelGroups();
    this.setState({
      yearSettingsCreationModalFormProps: {
        shown: true
      }
    });
  };

  handleHideYearSettingsCreationModalForm = () => {
    this.handleCleanModelGroups();
    this.setState({
      yearSettingsCreationModalFormProps: {
        shown: false
      }
    });
  };

  handleShowYearSettingsDetailModalForm = (yearSettingsRowData) => {
    this.setState({
      yearSettingsModalFormProps: {
        shown: true,
        yearSettingsData: yearSettingsRowData,
        readOnly: true
      }
    });
  }

  handleShowYearSettingsUpdateModalForm = (yearSettingsRowData) => {
    this.handleLoadModelGroups();
    this.setState({
      yearSettingsModalFormProps: {
        shown: true,
        yearSettingsData: yearSettingsRowData,
        readOnly: false
      }
    });
  }

  handleHideYearSettingsModalForm = () => {
    this.handleCleanModelGroups();
    this.setState({
      yearSettingsModalFormProps: {
        shown: false,
        yearSettingsData: null,
        readOnly: true
      }
    });
  };

  handleSaveYearSettingsModalForm = async (formData) => {
    const { onUpdateAnnualTargetYears, onAddAlertSimple } = this.props;

    try {
      const data = {
        coefficientFrom: formData.header.coefficientFrom,
        coefficientTo: formData.header.coefficientTo,
        periodCount: formData.header.periodCount,
        textMap: formData.texts.textMap,
        modelGroupList: formData.modelGroupWithMixRatioList,
        atSalesPeriodList: formData.header.atSalesPeriodList
      };
      const response = await onUpdateAnnualTargetYears(formData.atYearId, AnnualTargetYearUpdateDTO.constructFromObject(data));

      onAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.UPDATE_ANNUAL_TARGET_YEAR_SUCCESS');
      this.handleHideYearSettingsModalForm();
      this.handleReloadAnnualTargetYears();

      return response;
    } catch (e) {
      onAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.UPDATE_ANNUAL_TARGET_YEAR_FAILURE', AlertTypeEnum.WARNING);
    }
  };

  handleSaveYearSettingsCreationModalForm = async (formData) => {
    const { createAnnualTargetYear, onAddAlertSimple } = this.props;
    try {
      const data = {
        year: formData.header.year,
        coefficientFrom: formData.header.coefficientFrom,
        coefficientTo: formData.header.coefficientTo,
        periodCount: formData.header.periodCount,
        textMap: formData.texts.textMap,
        modelGroupList: formData.modelGroupWithMixRatioList,
        atSalesPeriodList: formData.header.atSalesPeriodList
      };
      const response = await createAnnualTargetYear(AnnualTargetYearNewDTO.constructFromObject(data));

      onAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.CREATE_ANNUAL_TARGET_YEAR_SUCCESS');
      this.handleHideYearSettingsCreationModalForm();
      this.handleReloadAnnualTargetYears();

      return response;
    } catch (e) {
      onAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.CREATE_ANNUAL_TARGET_YEAR_FAILURE', AlertTypeEnum.WARNING);
    }
  };

  getAvailableYearList = () => {
    const { negotiationList, calendarYearList } = this.props;

    const yearsUsed = negotiationList ? negotiationList.filter(n => n.isYearSettingsRow()).map(n => n.year) : [];
    const years = calendarYearList ? calendarYearList.filter(year => !yearsUsed.includes(year)) : [];

    return years;
  };

  handleUpdate = (negotiationData) => {
    const { negotiationModalFormProps } = this.state;
    if (negotiationModalFormProps.negotiationData.isNegotiationActivated()) {
      this.handleUpdateActivatedNegotiation(negotiationData);
    } else {
      this.handleUpdateNegotiation(negotiationData);
    }
  }

  renderNegotiationCreationModalForm = () => {
    const { dealerList, productionCapacityRatioList } = this.props;
    const { negotiationCreationModalFormProps } = this.state;

    if (negotiationCreationModalFormProps.shown) {
      return (
        <NegotiationCreationModalForm
          dealerList={dealerList}
          editRules={negotiationCreationModalFormProps.editRules}
          negotiationType={negotiationCreationModalFormProps.negotiationType}
          onClose={this.handleHideNegotiationCreationModalForm}
          onSave={this.handleCreateNegotiation}
          productionCapacityRatioList={productionCapacityRatioList}
          shown={negotiationCreationModalFormProps.shown}
          yearSettings={negotiationCreationModalFormProps.yearSettingsData}
        />
      );
    }
  };

  renderNegotiationModalForm = () => {
    const { dealerList, productionCapacityRatioList } = this.props;
    const { negotiationModalFormProps } = this.state;

    if (negotiationModalFormProps.shown) {
      return (
        <NegotiationModalForm
          dealerList={negotiationModalFormProps.readOnly ? null : dealerList}
          editRules={negotiationModalFormProps.editRules}
          negotiation={negotiationModalFormProps.negotiationData}
          onClose={this.handleHideNegotiationModalForm}
          onSave={negotiationModalFormProps.readOnly ? undefined : this.handleUpdate}
          productionCapacityRatioList={negotiationModalFormProps.readOnly ? null : productionCapacityRatioList}
          shown={negotiationModalFormProps.shown}
        />
      );
    }
  };

  renderYearSettingsCreationModalForm = () => {
    const { modelGroupList } = this.props;
    const { yearSettingsCreationModalFormProps } = this.state;

    if (yearSettingsCreationModalFormProps.shown) {
      return (
        <YearSettingsCreationModalForm
          modelGroupList={modelGroupList}
          onClose={this.handleHideYearSettingsCreationModalForm}
          onSave={this.handleSaveYearSettingsCreationModalForm}
          shown={yearSettingsCreationModalFormProps.shown}
          years={this.getAvailableYearList()}
        />
      );
    }
  };

  renderYearSettingsModalForm = () => {
    const { modelGroupList } = this.props;
    const { yearSettingsModalFormProps } = this.state;

    if (yearSettingsModalFormProps.shown) {
      return (
        <YearSettingsModalForm
          modelGroupList={yearSettingsModalFormProps.readOnly ? null : modelGroupList}
          onClose={this.handleHideYearSettingsModalForm}
          onSave={yearSettingsModalFormProps.readOnly ? undefined : this.handleSaveYearSettingsModalForm}
          shown={yearSettingsModalFormProps.shown}
          yearSettings={yearSettingsModalFormProps.yearSettingsData}
        />
      );
    }
  };

  render() {
    const lsi = this.context;
    const { isFetching, negotiationList } = this.props;

    return (
      <Page
        className='ovex-negotiations'
        header={lsi.getLSIItem('ANNUAL_TARGET.PAGE_TITLE.NEGOTIATIONS_MANAGEMENT')}
        loading={isFetching}
      >
        <NegotiationsTable
          negotiationList={negotiationList}
          onCancelNegotiationClosure={this.handleCancelNegotiationClosure}
          onCreateNegotiationClosure={this.handleCreateNegotiationClosure}
          onReloadAnnualTargetYears={this.handleReloadAnnualTargetYears}
          onShiftNegotiationWorkflow={this.handleShiftNegotiationWorkflow}
          onShowNegotiationCreationModalForm={this.handleShowNegotiationCreationModalForm}
          onShowNegotiationDetailModalForm={this.handleShowNegotiationDetailModalForm}
          onShowNegotiationUpdateModalForm={this.handleShowNegotiationUpdateModalForm}
          onShowYearSettingsCreationModalForm={isEmptyArray(this.getAvailableYearList()) ? undefined : this.handleShowYearSettingsCreationModalForm}
          onShowYearSettingsDetailModalForm={this.handleShowYearSettingsDetailModalForm}
          onShowYearSettingsUpdateModalForm={this.handleShowYearSettingsUpdateModalForm}
        />
        {this.renderNegotiationCreationModalForm()}
        {this.renderNegotiationModalForm()}
        {this.renderYearSettingsCreationModalForm()}
        {this.renderYearSettingsModalForm()}
      </Page>
    );
  }
}

export default Negotiations;
