import React from 'react';
import PropTypes from 'prop-types';

import ModelGroupAnnualTargetTable from '../ModelGroupAnnualTargetTable/ModelGroupAnnualTargetTable';
import PeriodAnnualTargetSalesTable from '../PeriodAnnualTargetSalesTable/PeriodAnnualTargetSalesTable';
import BEVAnnualTargetSalesTable from '../BEVAnnualTargetSalesTable/BEVAnnualTargetSalesTable';

import DealerContractRow from './DealerContractRow';
import DealerContractsTableEditableContext from './DealerContractsTableEditableContext';
import { COLUMN_GROUP_IDS } from './DealerContractsTable.columnIds';

/**
 * Horizontal space in pixels. Same value as SASS variable **$ovex-horizontal-space**
 * @type {number}
 */
const horizontalSpace = 16;

const DealerContractsTableDetailComponent = (props) => {
  const { api, data, context, node, rowIndex, className } = props;
  const { hiddenROC, hiddenRCP, onShowCommissionListWithParams } = context;

  const editable = React.useContext(DealerContractsTableEditableContext);
  const classNames = ['ovex-dealer-contracts-table-detail'];
  className && classNames.push(className);

  const detailGridId_atPeriod = `ri_${rowIndex}__detail_at_period`;
  const detailGridId_atSalesPeriod = `ri_${rowIndex}__detail_at_sales_period`;
  const detailGridId_atBEVSalesPeriod = `ri_${rowIndex}__detail_at_bev_sales_period`;

  const gridHeight_atPeriod = React.useRef(0);
  const gridHeight_atSalesPeriod = React.useRef(0);
  const gridHeight_atBEVSalesPeriod = React.useRef(0);

  const calculateAndSetMasterRowHeight = () => {
    let rowHeight = horizontalSpace;
    !hiddenROC && (rowHeight += gridHeight_atPeriod.current + horizontalSpace);
    !hiddenRCP && (rowHeight += gridHeight_atSalesPeriod.current + horizontalSpace);
    (!hiddenROC & !hiddenRCP) && (rowHeight += gridHeight_atBEVSalesPeriod.current + horizontalSpace);

    // https://www.ag-grid.com/react-data-grid/row-height/#changing-row-height
    node.setRowHeight(rowHeight);
    api.onRowHeightChanged();
  };

  const onGridSizeChanged_atPeriod = (params) => {
    if (params.clientHeight === 0) {
      // AF-13154 workaround - in newer AG-Grid versions gridSizeChanged will not necessary
      return;
    }
    gridHeight_atPeriod.current = params.clientHeight;
    calculateAndSetMasterRowHeight();
  };

  const onGridSizeChanged_atSalesPeriod = (params) => {
    if (params.clientHeight === 0) {
      // AF-13154 workaround - in newer AG-Grid versions gridSizeChanged will not necessary
      return;
    }
    gridHeight_atSalesPeriod.current = params.clientHeight;
    calculateAndSetMasterRowHeight();
  };

  const onGridSizeChanged_atBEVSalesPeriod = (params) => {
    if (params.clientHeight === 0) {
      // AF-13154 workaround - in newer AG-Grid versions gridSizeChanged will not necessary
      return;
    }
    gridHeight_atBEVSalesPeriod.current = params.clientHeight;
    calculateAndSetMasterRowHeight();
  };

  const atGridOptions = { alignedGrids: [] };
  const atsGridOptions = { alignedGrids: [] };
  const atsBEVGridOptions = { alignedGrids: [] };
  atGridOptions.alignedGrids.push(atsGridOptions);
  atGridOptions.alignedGrids.push(atsBEVGridOptions);
  atsGridOptions.alignedGrids.push(atGridOptions);
  atsGridOptions.alignedGrids.push(atsGridOptions);
  atsBEVGridOptions.alignedGrids.push(atGridOptions);
  atsBEVGridOptions.alignedGrids.push(atsGridOptions);

  const doneCountGroupExpanded = !!api.columnModel.getColumnGroup(COLUMN_GROUP_IDS.STATISTICS_DONE_COUNT_GROUP)?.isExpanded();
  const potentialCountGroupExpanded = !!api.columnModel.getColumnGroup(COLUMN_GROUP_IDS.STATISTICS_POTENTIAL_COUNT_GROUP)?.isExpanded();

  return (
    <div
      className={classNames.join(' ')}
    >
      { !hiddenROC &&
        <ModelGroupAnnualTargetTable
          contract={data.contract}
          detailGridId={detailGridId_atPeriod}
          doneCountGroupExpanded={doneCountGroupExpanded}
          editable={editable}
          gridOptions={atGridOptions}
          masterGridApi={api}
          modelGroupAnnualTargetList={data.contract.modelGroupAnnualTargetList}
          onGridSizeChanged={onGridSizeChanged_atPeriod}
          onShowCommissionListWithParams={onShowCommissionListWithParams}
          potentialCountGroupExpanded={potentialCountGroupExpanded}
        />
      }
      { !hiddenRCP &&
        <PeriodAnnualTargetSalesTable
          contract={data.contract}
          detailGridId={detailGridId_atSalesPeriod}
          doneCountGroupExpanded={doneCountGroupExpanded}
          editable={editable}
          gridOptions={atsGridOptions}
          masterGridApi={api}
          onGridSizeChanged={onGridSizeChanged_atSalesPeriod}
          onShowCommissionListWithParams={onShowCommissionListWithParams}
          periodAnnualTargetSalesList={data.contract.periodAnnualTargetList}
        />
      }
      { (!hiddenROC & !hiddenRCP) &&
        <BEVAnnualTargetSalesTable
          contract={data.contract}
          detailGridId={detailGridId_atBEVSalesPeriod}
          gridOptions={atsBEVGridOptions}
          masterGridApi={api}
          onGridSizeChanged={onGridSizeChanged_atBEVSalesPeriod}
          onShowCommissionListWithParams={onShowCommissionListWithParams}
          bevAnnualTargetSales={data.contract.bevAnnualTargetSales}
        />

      }
    </div>
  );
};

const DealerContractsTableDetail = React.memo(DealerContractsTableDetailComponent);

DealerContractsTableDetailComponent.propTypes = {
  className: PropTypes.string,
  data: PropTypes.instanceOf(DealerContractRow).isRequired
};

DealerContractsTableDetailComponent.defaultProps = {
  className: null
};

export default DealerContractsTableDetail;
