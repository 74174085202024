import React from 'react';

import { Checkbox } from '../../../../../../common/components/forms';
import './CheckBoxRenderer.scss';

const CheckBoxRenderer = (params, editable) => {
  const [value, setValue] = React.useState(params.value);
  return (
    <div className={'ovex-check-box-renderer'}>
      <Checkbox
        disabled={!editable}
        onChange={(checkBoxparams) => {
          setValue(checkBoxparams);
          params.onChange(checkBoxparams);
        }}
        type={1}
        value={value}
      />
    </div>
  );
};

export default CheckBoxRenderer;